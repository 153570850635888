import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { ReactComponent as GreeneyeLogo } from "assets/Greeneye-logo.svg";
import { SigninCard } from "components/Card";
import { useRedirectToHomeIfAuthed } from "hooks";
import { useSelectedLanguage } from "language";
import { Link } from "react-router-dom";
import * as SC from "./style";

const SignIn = () => {
  useRedirectToHomeIfAuthed();
  const { selectedLanguage, selectedDictionary } = useSelectedLanguage();
  return (
    <SC.Background>
      <SC.HeaderLogoWrapper>
        <Link to="/">
          <GreeneyeLogo height="30" />
        </Link>
      </SC.HeaderLogoWrapper>
      <Grid
        container
        direction="row"
        justifyContent="space-evenly"
        p={{ md: "4rem", xs: "2rem" }}
      >
        <Grid item sm={5}>
          <SC.BodyWrapper>
            <Typography variant="h1" color="white" gutterBottom>
              {(selectedDictionary as any).responsibleInvestmentExpert}
            </Typography>
            {selectedLanguage === "he" && (
              <Typography variant="h3" color="white" gutterBottom>
                {(selectedDictionary as any).header}
                <br />
                {(selectedDictionary as any).subheader1}
                <br />
                {(selectedDictionary as any).subheader2}
                <br />
                {(selectedDictionary as any).subheader3}
                <br />
                {(selectedDictionary as any).subheader4}
                <br />
                <span>
                  {(selectedDictionary as any).subheader5}{" "}
                  <a href=""> {(selectedDictionary as any).subheaderemail} </a>
                </span>
              </Typography>
            )}
            {selectedLanguage === "en" && (
              <Typography variant="h3" color="white" gutterBottom>
                {(selectedDictionary as any).header}
                <br />
                {(selectedDictionary as any).subheader1}
              </Typography>
            )}
          </SC.BodyWrapper>
        </Grid>
        <Grid item sm={2} />
        <Grid item sm={5} pt={{ xs: "2rem", sm: 0 }}>
          <SigninCard />
        </Grid>
      </Grid>
    </SC.Background>
  );
};

export default SignIn;
