export default {
  filters: {
    filters: "בחר.י קריטריונים להשוואה",
    clearAll: "נקה הכל",
    indecies: "מדדים",
    ipos: "מונפקות",
    experimentalIndex: "מדד ניסיוני",
    bonds: 'חברות אג"ח',
    telAviv125: "תל אביב 125",
    generalShares: "מניות כללי",
    sectors: "סקטורים",
    letter: "דירוג ESG אותיות",
  },
  sectors: {
    renewableEnergy: "אנרגיה מתחדשת",
    credit: "אשראי",
    insurance: "ביטוח",
    commercialBanks: "בנקים מסחריים",
    fuelDistribution: "הפצת דלקים",
    realEstateInvestment: 'השקעה בנדל"ן',
    oilRefining: "זיקוק דלק",
    oilGasExpProd: "חיפוש והפקת נפט וגז",
    agriculture: "חקלאות",
    electricityUtility: "יצרניות חשמל",
    financialInstitutions: "מוסדות פיננסיים",
    generalManufacturors: "יצרנים - כללי",
    chemicals: "כימיקלים",
    semiconductors: "מוליכים למחצה",
    buildingMaterials: "מוצרי בניה",
    electronics: "מוצרי חשמל",
    foods: "מזון",
    biotechnology: "מחקר ביוטכנולוגי",
    matchMediaachinery: "מיכון",
    hotelsAndTourism: "מלונאות ותיירות",
    realEstate: 'נדל"ן ובניה',
    utility: "ניהול ואחזקת תשתיות ומתקנים",
    supermarkets: "סופרמרקטים וקמעונאות מזון",
    woodAndPaper: "עץ, נייר וקרטון",
    medicalSolution: "פיתוח מכשור ופתרונות רפואיים",
    plastics: "פלסטיק וגומי",
    pharmaceutics: "פרמצבטיקה",
    retail: "קמעונאות - כללי",
    robotics: "רובוטיקה ותלת-מימד",
    wellfareAndHealthcare: "שירותי רווחה ובריאות",
    shipping: "תובלה ולוגיסטיקה",
    software: "תוכנה",
    airlines: "תחבורה אווירית",
    aerospace: "תעופה ובטחון",
    communications: "תקשורת ומדיה",
    communicationsInfrastructure: "תשתיות וציוד תקשורת",
  },
  esgGrade: "ציון ESG",
  min: "מימימום",
  max: "מקסימום",
  esgComparedToSector: "דירוג ESG ביחס לענף",
  equalOrAboveAvg: "מעל או שווה לממוצע",
  belowAverage: "מתחת לממוצע",
  esgGradePercentile: "ציון ESG לפי אחוזון",
  percentileInSector: "אחוזון בענף",
  incidentsAndAlerts: "אירועים חריגים והתראות",
  incidents: "חברות עם אירועים חריגים ללא התראה",
  alerts: "חברות עם התראה לתשומת לב עקב אירועים חריגים",
  redFlagAlerts: "חברות עם התראת דגל אדום עקב אירועים חריגים",
  noIncidents: "חברות בלי אירועים חריגים",
  sustainableDevelopment: "פעילויות עם השפעה חיובית",
  yes: "יש",
  no: "אין",
  criticalEvents: "אירועים קריטיים",
  health: "בריאות",
  foodSecurity: "בטחון תזונתי",
  safety: "בטיחות",
  financialInclusion: "הנגשה פיננסית",
  publicTransport: "הסעת המונים",
  pollutionReduction: "הפחתת זיהום",
  elseducation: "חינוך",
  sustainableElectricity: "חשמל",
  telecommunications: "טלקומוניקציה",
  waterAndSanitation: "מים וסניטציה",
  climateChangeAdaptation: "שינוי אקלים - הסתגלות",
  climateChangeMitigation: "שינוי אקלים - הפחתה",
  disputableGoods: "פעילויות שנויות במחלוקת",
  failossilFuels: "דלקים פוסיליים - גז, פחם, נפט",
  gambling: "הימורים",
  pesticides: "חומרי הדברה",
  tobacco: "טבק",
  indiscriminateWeapons: "ייצור נשק שלא מבדיל",
  chemicalsProhibitedByInternationalConventions:
    "כימיקלים אסורים לשימוש על פי אמנות בינלאומיות",
  naturalResourceMining: "כריית משאבי טבע",
  animalTesting: "מוצרים שנוסו על בעלי חיים לצורך רפואי",
  securitySystems: "מכירות של מערכות בטחוניות",
  nuclearEnergy: "מעורבות אנרגיה גרעינית",
  nuclearArms: "מעורבות נשק גרעיני",
  saleManufacturingArmsForCivilians: "נשק אזרחי",
  Pornography: "פורנוגרפיה",
  card: {
    sector: "ענף",
    greeneyeSector: "סקטור Greeneye",
    socialGrade: "ציון חברה",
    gorvernanceGrade: "ציון ממשל",
    environmentalGrade: "ציון סביבה",
    incidents: "אירועים חריגים",
    involvementInDisputableActivities: "מעורבות בפעילויות בעייתיות",
  },
  companyReport: "הצג דוח חברה",
  exit: "יציאה",
  additionalCompanies: "חברות נוספות",
  additionalFunds: "כספים נוספים",
  loading: "טוען",
  found: "נמצאו",
  companies: "חברות",
  dataSummary: "נתונים מסכם",
  elaborateDataExport: "ייצוא נתונים מפורט",
  analysisSummary: "סיכומי הערכות",
  login: "כניסה למערכת",
  email: "דואר אלקטרוני",
  password: "סיסמא",
  enter: "כניסה",
  responsibleInvestmentExpert: "מומחים להשקעות אחראיות",
  header:
    "חברת Greeneye - מובילה מאז 2006 בהערכת סיכוני והזדמנויות ESG (סביבה, חברה וממשל תאגידי)",
  subheader1: "מערכת Greeneye ESG Data מציעה:",
  subheader2: "• הערכות ESG מקיפות לחברות מונפקות",
  subheader3: "• כלי השוואה וסינון מתקדמים",
  subheader4: "• הערכות ESG לתיקי השקעה וקרנות - מוזמנים ליצור קשר",
  subheader5: "זמינים לכל שאלה:",
  subheaderemail: "contact@greeneye.co.il",
  companyResponsiveness: "הענות חברה",
  analystName: "אנליסט.ית",
  analysisDate: "תאריך הערכה שנתית",
  companySearch: "חפש חברה",
  fundSearch: "חפש קרן",
  downloading: "מוריד...",
  noResult: "לא נמצאו חברות, נסה מסנן אחר",
  noResultFunds: "לא נמצאו קרנות",
  grade: "ציון",
  exposure: "מידת חשיפה",
  management: "ניהול",
  risk: "לְהִסְתָכֵּן",
  companiesComparisonCell1: "אירועים חריגים",
  companiesComparisonCell2: "סביבה",
  companiesComparisonCell3: "חברה",
  companiesComparisonCell4: "ממשל תאגידי",
  companiesComparisonCell5: "השוואה בין חברות בסקטור",
  companyDetailReportCell1: "נושא",
  companyDetailReportCell2: "מידת חשיפה",
  companyDetailReportCell3: "ניהול",
  activityCell1: "אין נושאים להצגה",
  companyEvaluationCell1: "תחום",
  companyEvaluationCell2: "רמת המהותיות של הנושא למגזר",
  companyEvaluationCell3: "הערכה כוללת תחום",
  activeCountriesCell1: "מדינות פעילות עיקרית",
  activeCountriesCell2: "מפותחות",
  activeCountriesCell3: "מתפתחות",
  הערהCell1: "הערה",
  esgGradeCell1: "הערכה כוללת להתנהלות ESG",
  esgGradeCell2: "ציון חברה",
  esgGradeCell3: "ממוצע ענף",
  esgGradeCell4: "טווח הציונים בענף",
  companyIssueCell1: "מידת חשיפה",
  companyIssueCell2: "ניהול",
  incidentsReportCell1: "נושא",
  incidentsReportCell2: "חומרה",
  incidentsReportCell3: "תגובה",
  reportIndexCell1: "פעילות החברה",
  reportIndexCell2: "תחום סביבה",
  reportIndexCell3: "תחום חברה",
  reportIndexCell4: "תחום ממשל תאגידי",
  reportIndexCell5: "סיכום אנליסט על החברה",
  reportIndexCell6: "אירועים חריגים",
  reportIndexCell7: "פעילויות עם השפעה חיובית",
  reportIndexCell8: "פעילויות שנויות במחלוקת",
  reportIndexCell9: "תמצית הערכת התנהלות חברה",
  reportIndexCell10: "הערכה מפורטת",
  reportIndexCell11: "תחום סביבה",
  reportIndexCell12: "תחום חברה",
  reportIndexCell13: "תחום ממשל תאגידי",
  reportIndexCell14: "הדפסה",
  reportIndexCell15: "תחום סביבה",
  reportIndexCell16: "תחום חברה",
  reportIndexCell17: "תחום ממשל תאגידי",
  reportIndexCell18: "סביבה",
  reportIndexCell19: "חברה",
  reportIndexCell20: "ממשל תאגידי",
  progressCell1: "מינימום ענף",
  progressCell2: "ממוצע ענף",
  progressCell3: "מקסימום ענף",
  formattersCell1: "סביבה",
  formattersCell2: "חברה",
  formattersCell3: "ממשל תאגידי",
  sources: "מקורות",
  equalOrAboveAverage: "מעל או שווה לממוצע",
  belowAverage: "מתחת לממוצע",
  options: "אפשרויות",
  letter: "אותיות",
  number: "מספרים",
  climateChangeRating: "ציון שינוי אקלים",
  expeditingActivities: "פעילות מקדמת",
  delayingActivities: "פעילות מעכבת",
  funds: {
    funds: "קרנות גרסת בטא",
    companies: "חברות",
    fundGrade: "ציון קרן",
    percentAssessed: "אחוז מנכסי הקרן שמוערך על ידי Greeneye",
    fundDetails: "פרטי הקרן",
    benchmarkGrade: "ציון נכס יחוס",
    date: "תאריך",
    weightedGrade: "ציון משוקלל",
    back: "חזור",
    ticker: "Ticker",
    company: "שם החברה",
    isRedFlag: "דגל",
    weight: "משקל  (%)",
    grade: "ציון Greeneye",
    normalizedWeight: "משקל מנורמל",
    weightedGrade: "ציון משוקלל",
    goodProducts: "פעילויות עם השפעה חיובית",
    controversialActivities: "פעילויות שנויות במחלוקת",
    redFlags: "דגלים אדומים",
    benchmark: "נכס יחוס",
    assetId: "ID/ISIN",
    fundDate: "עדכון אחרון",
    fundsDisclaimer: "לפי משקל מנורמל",
    fundNumber: "מספר קרן",
  },
  progressionGraph: "גרף התקדמות",
  noHistoryWarning: "לחברה אין עדיין היסטוריית ציונים",
  issuerId: "מספר מנפיק",
  companyName: "שם חברה",
  securityName: "שם נייר ערך",
  securityIsin: "ISIN",
  securityTaseId: "מספר נייר ערך",
  searchBy: "חפש לפי",
  indexFilter: "מסנן אינדקס",

  ISSUER_ID: "מספר מנפיק",
  COMPANY_NAME: "שם חברה",
  SECURITY_NAME: "שם נייר ערך",
  SECURITY_ISIN: "ISIN",
  SECURITY_TASE_ID: 'מספר ני"ע',
};
